import { blue } from "@mui/material/colors";
import React from "react";

const PrivacyPolicyPage = () => (
    <>
        <div style={{"text-align": "left", "color": blue}}>
            <h1>Privacy Policy</h1>

            <p>This Privacy Policy governs the manner in which we collect, use, maintain, and disclose information collected from users.</p>

            <h3>Information Collection and Use</h3>

            <p>We do not collect or store any personal information from users of any service. Our apps operate fully offline and do not require or utilize any data connection, other than the initial download from the relevant App store. Therefore, we do not collect any data, including but not limited to:</p>
            <ul>
                <li>Names</li>
                <li>Email addresses</li>
                <li>Phone numbers</li>
                <li>Location information</li>
                <li>Device identifiers</li>
                <li>Usage data</li>
            </ul>

            <p>If you use the `Contact Us` page, any information you provide, will only exist for the duration of your query.</p>

            <h3>Advertising</h3>
            <p>The legacy website, soon to be replaced, contains an Advert. This is set to disappear once the site switch-over occurs.</p> 
            
            <p>Information when using the old site (Without `/new/` in the URL) may share information with Google in order to serve those ads.</p>

            <p>At no time do apps interface with the website, and are therefore not affected.</p>

            <a href="https://policies.google.com/privacy">View Google's Privacy Policy</a>

            <h3>Data Storage</h3>

            <p>Since our apps do not collect any data, no data is stored internally. The old website adverts may differ.</p>

            <h3>Third-Party Services</h3>

            <p>Our apps do not integrate with any third-party services that collect user data.</p>

            <h3>Changes to This Privacy Policy</h3>

            <p>We have the discretion to update this Privacy Policy at any time. We encourage users to frequently check this page for any changes. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.</p>

            <h3>Your Acceptance of These Terms</h3>

            <p>By using our services, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our application. Your continued use of the application following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>

            <h3>Contacting Us</h3>

            <p>If you have any questions about this Privacy Policy, the practices of, or dealings with our services, please use the `Contact Us` page.</p>

            <p>This document was last updated on 8th April 2024.</p>
        </div>
    </>
);

export default PrivacyPolicyPage;
