import React from "react";
import { Typography, TextField, Button } from "@mui/material";

const ContactPage = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("https://jpkey.co.uk/contact", {
      method: "POST",
      body: data,
      mode: "no-cors",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography component="h2" variant="headline" gutterBottom>
        Contact
      </Typography>
      <TextField label="Name" variant="standard" fullWidth />
      <TextField label="Email" variant="standard" fullWidth />
      <TextField label="Subject" variant="standard" fullWidth />
      <TextField label="Message" variant="standard" fullWidth multiline />
      <Button type="submit">Send</Button>
    </form>
  );
};

export default ContactPage;
