import React from "react";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import wrapURL from "../WrapUrl";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  link: {
    color: "#FFF",
    textDecoration: "none",
  }
};

function BottomBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            &copy; JPKey
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <Grid container direction="column" spacing="2">
            <Grid item>
              <p>
                All information on this website is subject to change without
                prior notice!
              </p>
            </Grid>
            <Grid>&nbsp;</Grid>
            <Grid item direction="row" spacing="2">
              <Grid sx={3} item direction="column" spacing="2">
                <Grid>
                  <b>Contact</b>
                </Grid>
                <Grid>james@jpkey.co.uk</Grid>
                <Grid item>
                </Grid>
              </Grid>
              <Grid sx={3} item direction="column" spacing="2">
                <Grid>
                  <Link to={wrapURL("/privacy")} className={classes.link}>
                    <b>Privacy Policy</b>
                  </Link>
                </Grid>
                <Grid>&nbsp;</Grid>
                <Grid item>&copy; JPKey {new Date().getFullYear()}</Grid>
                <Grid>&nbsp;</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(BottomBar);
