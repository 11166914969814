import React from "react";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import wrapURL from "../WrapUrl";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    textAlign: "left"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  link: {
    color: "#000",
    textDecoration: "none"
  }
};

function TopBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            <Link to={wrapURL("/")} className={classes.link}>
              James Key
            </Link>
          </Typography>
          <Button component={Link} to={wrapURL("/about")} color="inherit">About</Button>
          <Button component={Link} to={wrapURL("/docs")} color="inherit">Documents</Button>
          {/* <Button component={Link} to={wrapURL("code")} color="inherit">Code</Button> */}
          <Button component={Link} to={wrapURL("/gallery")} color="inherit">Gallery</Button>
          <Button component={Link} to={wrapURL("/contact")} color="inherit">Contact</Button>
          {/* <Button component={Link} to={wrapURL("blog")} color="inherit">Blog</Button> */}
          <Button component={Link} to={wrapURL("/privacy")} color="inherit">Privacy</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(TopBar);
