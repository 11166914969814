import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import { createTheme } from "@mui/material";
import { Button, Drawer, ThemeProvider, CssBaseline, Paper } from "@mui/material";
import HomePage from "./containers/HomePage";
import AboutPage from "./containers/AboutPage";
import DocumentPage from "./containers/DocumentPage";
import CodePage from "./containers/CodePage";
import GalleryPage from "./containers/GalleryPage";
import ContactPage from "./containers/ContactPage";
import BlogPage from "./containers/BlogPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage";
import wrapURL from "./WrapUrl";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#4fc3f7",
    },
    secondary: {
      main: "#0080FF",
    },
  },
});

const App = () => {
  const [consentDismissed, setConsentDismissed] = useState(false);
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <BrowserRouter>
        <div className="App">
          <TopBar />
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Paper style={{ marginTop: 16, padding: 8 }}>
              <Routes>
                <Route path={wrapURL("/about")} element={<AboutPage/>} />
                <Route path={wrapURL("/docs")} element={<DocumentPage/>} />
                <Route path={wrapURL("/code")} element={<CodePage/>} />
                <Route path={wrapURL("/gallery")} element={<GalleryPage/>} />
                <Route path={wrapURL("/contact")} element={<ContactPage/>} />
                <Route path={wrapURL("/blog")} element={<BlogPage/>} />
                <Route path={wrapURL("/privacy")} element={<PrivacyPolicyPage/>} />
                <Route path={wrapURL("/")} element={<HomePage/>} />
              </Routes>
            </Paper>
          </div>
        </div>
        <BottomBar />
        <Drawer
            anchor="bottom"
            open={!consentDismissed}
          >
            <div style={{padding: "16px"}}>
              <h4>Cookie Preferences</h4>
              <p>Jpkey.co.uk uses essential cookies, including third party cookies to ensure our site can function.</p>
              <p>In future, we may request to set non-essential cookies to personalise and improve your experience as well as deliver relevant advertising. You can refuse this.</p>
              <p>Read more in our Privacy Policy.</p>

              <Button onClick={() => setConsentDismissed(true)}>Reject Non-essential</Button> <Button variant="contained" onClick={() => setConsentDismissed(true)}>Accept All</Button>
            </div>
          </Drawer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
