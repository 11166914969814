import React from 'react';
import { Typography } from '@mui/material';

const BlogPage = () => (
  <>
    <Typography component="h2" variant="headline" gutterBottom>
      Blog
    </Typography>
    <p>Blog coming soon...</p>
  </>
);

export default BlogPage;