import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

const DocumentPage = () => (
  <>
    <Typography component="h2" variant="headline" gutterBottom>
      Documents
    </Typography>
    <Grid container>
      <Grid item xs={6}>
        <Card style={{ padding: 8 }}>
          <Typography component="h2" variant="headline" gutterBottom>
            Curriculum Vitae
          </Typography>
          <Grid container>
              <Grid item>
                <Card 
                  style={{ height: 150, width: 150 }}
                  onClick={() =>
                    (window.location = "https://www.jpkey.co.uk/docs/CV.pdf")
                  }
                >
                  <DescriptionIcon />
                  <CardContent style={{textAlign: "center"}}>
                    CV
                  </CardContent>
                </Card>
              </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: 8 }}>
          <Typography component="h2" variant="headline" gutterBottom>
            Publications
          </Typography>
          <Grid container>
              <Grid item>
                <Card 
                  style={{ height: 150, width: 150 }}
                  onClick={() =>
                    (window.location =
                      "https://www.jpkey.co.uk/docs/dissertation.pdf")
                  }
                >
                  <DescriptionIcon />
                  <CardContent style={{textAlign: "center"}}>
                    Workforce Constraint Validation
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card 
                  style={{ height: 150, width: 150 }}
                  onClick={() =>
                    (window.location =
                      "https://www.jpkey.co.uk/docs/mscidissertation.pdf")
                  }
                >
                  <DescriptionIcon />
                  <CardContent style={{textAlign: "center"}}>
                    Interactive Visualisation of 3D Root Structures
                  </CardContent>
                </Card>
              </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>
);

export default DocumentPage;
