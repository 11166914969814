import React from "react";
import { Typography, Card, CardMedia } from "@mui/material";

const AboutPage = () => (
  <>
    <Typography component="h2" variant="headline" gutterBottom>
      About
    </Typography>
    <Card style={{ margin: "auto", height: 150, width: 150 }}>
      <CardMedia image="/me.jpg" style={{ height: 150, width: 150 }} />
    </Card>
    <p>
      My name is James, and I am a Software Engineer in Nottingham, England. I
      currently work in iOS Development at Experian. Following eight years at Games Workshop.
    </p>
    <p>
      My passion for computing began upon receiving my first desktop at the age
      of 12. Feeling a sense of freedom, I played around constantly using google
      to search for constant solutions, with mostly consumer goals such as
      playing games and using localised hacks. Originally, I grew up wanting to
      be a doctor, seeing a PC as a mere tool, until half-way through secondary
      school, when some great friendships formed with the schools `geek`s,
      skilled in web development.
    </p>
    <p>
      I was shown a student-created, hacking simulation site, which inspired me
      to learn HTML and CSS at 14. During that time, we co-developed a browser
      role-playing game, and another hacking simulation, yet alas the host was
      shutdown, and the naivety of youth meant backups were non-existent.
    </p>
    <p>
      Since then, an ever increasing technical knowledge, has caused interest to
      soar. Having completed a BTEC National Diploma (2011), and a 2:1 Masters
      degree in Computer Science (2015), i have learned to develop for a
      multitude of devices, including Android, Windows and linux, and more
      recently, iOS and MacOS. With a keen interest in graphical applications,
      such as edge detection, OpenGL, and game algorithms, I generally lack a
      creative instinct, being more inclined to technical-thinking, and
      optimising functional aspects.
    </p>
  </>
);

export default AboutPage;
