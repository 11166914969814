import React, { useState } from "react";
import { Typography, Card, CardMedia, Grid, Backdrop } from "@mui/material";
import wrapURL from "../WrapUrl";

const GalleryPage = () => {
  let blenderImages = [
    "blender/full/3bear.jpg",
    "blender/full/5D.png",
    "blender/full/EsperonDawn.png",
    "blender/full/EsperonEarth.png",
    "blender/full/EsperonLith.png",
    "blender/full/Jail-gpu-6.9k.jpg",
    "blender/full/animechar.png",
    "blender/full/animeguy4k.png",
    "blender/full/animetoon2g.png",
    "blender/full/animetoongirl2.png",
    "blender/full/bar.jpg",
    "blender/full/binary.jpg",
    "blender/full/calendar.png",
    "blender/full/castle.jpg",
    "blender/full/church4k.jpg",
    "blender/full/cityblocks2.jpg",
    "blender/full/classroom.jpg",
    "blender/full/cubes3k.png",
    "blender/full/diskimager.png",
    "blender/full/dojo5k.jpg",
    "blender/full/gem2.png",
    "blender/full/gems.jpg",
    "blender/full/glass_cloth_1000.jpg",
    "blender/full/grass.jpg",
    "blender/full/icon_folder.png",
    "blender/full/jpkey.png",
    "blender/full/lab.jpg",
    "blender/full/mannakin_carousel_2.jpg",
    "blender/full/mockup.png",
    "blender/full/new_years.jpg",
    "blender/full/organ.png",
    "blender/full/pumpkin.png",
    "blender/full/putty.png",
    "blender/full/roboDino.jpg",
    "blender/full/robot.png",
    "blender/full/room5kgpu.jpg",
    "blender/full/sea_rocks_2.png",
    "blender/full/sea_rocks_t.png",
    "blender/full/shop.jpg",
    "blender/full/skull.png",
    "blender/full/spaceship.png",
    "blender/full/stage8k.jpg",
    "blender/full/stairs2.png",
    "blender/full/swords2.png",
    "blender/full/tajmahal.jpg",
    "blender/full/tunnel-gpu-1.2k.jpg",
    "blender/full/underwater.jpg",
  ];

  let androidImages = [
    "android/full/address.jpg",
    "android/full/bin_conv.jpg",
    "android/full/fotp.jpg",
    "android/full/space.jpg",
    "android/full/timer.jpg",
  ];
  let websiteImages = [
    "websites/full/epo.png",
    "websites/full/jnet_portal.png",
    "websites/full/kinesiuv2.png",
    "websites/full/lastlight.png",
    "websites/full/pro-rota.png",
    "websites/full/tfk.png",
  ];

  const [selected, setSelected] = useState(null);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!selected}
        onClick={() => setSelected(null)}
      >
        <img src={selected} />
      </Backdrop>
      <Typography component="h2" variant="headline" gutterBottom>
        Gallery
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ padding: 8 }}>
            <Typography component="h2" variant="headline" gutterBottom>
              Blender
            </Typography>
            <Grid container>
              {blenderImages.map((img) => (
                <Grid item>
                  <Card key={img} style={{ height: 150, width: 150 }}>
                    <CardMedia
                      image={`https://www.jpkey.co.uk/galleries/${img}`}
                      style={{ height: 150, width: 150 }}
                      onClick={() => setSelected(wrapURL(`/galleries/${img}`))}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ padding: 8 }}>
            <Typography component="h2" variant="headline" gutterBottom>
              Android
            </Typography>
            <Grid container>
              {androidImages.map((img) => (
                <Grid item>
                  <Card key={img} style={{ height: 150, width: 100 }}>
                    <CardMedia
                      image={`https://www.jpkey.co.uk/galleries/${img}`}
                      style={{ height: 150, width: 100 }}
                      onClick={() => setSelected(wrapURL(`/galleries/${img}`))}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ padding: 8 }}>
            <Typography component="h2" variant="headline" gutterBottom>
              Websites
            </Typography>
            <Grid container>
              {websiteImages.map((img) => (
                <Grid item>
                  <Card key={img} style={{ height: 100, width: 150 }}>
                    <CardMedia
                      image={`https://www.jpkey.co.uk/galleries/${img}`}
                      style={{ height: 100, width: 150 }}
                      onClick={() => setSelected(wrapURL(`/galleries/${img}`))}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default GalleryPage;
