import React from "react";
import { Typography } from "@mui/material";

const CodePage = () => (
  <>
    <Typography component="h2" variant="headline" gutterBottom>
      Code
    </Typography>
    <p>Scripts coming soon...</p>
  </>
);

export default CodePage;
